<template>
  <div class="about container">

     <h1>О нас</h1>




     <p style="color: red">пишим от торгового дома, ссылаемся на завод. Сайт завода должен быть один.</p>
     <p style="color: red">надо гораздо больше текста</p>


       <p>ООО «Проммаш» производит практически весь спектр оборудования для получения таких смесей включая подготовку компонентов для их смешивания, дозирования компонентов для подачи их в смеситель, смешивания компонентов, просеивания как компонентов смеси, так и уже готовой смеси. Размерный ряд оборудования охватывает производительность от 2 т/ч до 20 т/ч.</p>


  </div>
</template>
